import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const CounterUnits = () => {
    const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);
    const selectedUnitNation2 = useSelector((state: RootState) => state.units.selectedUnitNation2);
    const selectedNation1 = useSelector((state: RootState) => state.units.selectedNation1);
    const selectedNation2 = useSelector((state: RootState) => state.units.selectedNation2);
    const [minutes, setMinutes] = useState<number>(0);
    const [units1Builded, setUnits1Built] = useState<number>(0);
    const [units2Builded, setUnits2Built] = useState<number>(0);
    const countUnits = () => {
        const buildTime1 = selectedUnitNation1?.buildtime || 0;
        const countUnits1 = Math.floor(minutes * 60 / buildTime1);
        const buildTime2 = selectedUnitNation2?.buildtime || 0;
        const countUnits2 = Math.floor(minutes * 60 / buildTime2);
        setUnits1Built(countUnits1);
        setUnits2Built(countUnits2);
    }
    useEffect(() => {
        countUnits();
    }, [minutes, selectedUnitNation1, selectedUnitNation2]);
    return (
        <div>
            <label className='label-minutes'>Minutes
                <input type="number" onChange={(e) => setMinutes(parseInt(e.target.value))} />
            </label>
            {units1Builded !== Infinity && units1Builded > 0 ? <p>{selectedNation1?.name} - {selectedUnitNation1?.name.split(',', 1)[0]}: {units1Builded}</p> : null}
            {units2Builded !== Infinity && units2Builded > 0 ? <p>{selectedNation2?.name} - {selectedUnitNation2?.name.split(',', 1)[0]}: {units2Builded}</p> : null}
        </div>
    )
}

export default CounterUnits