import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnitPreview from '../UnitPreview';
import { RootState, AppDispatch } from '../../../store'; // Adjust the path as necessary
import { recalculateUnitPreview2 } from '../../../store/unitsSlice';

const Unit2Preview = () => {
  const dispatch: AppDispatch = useDispatch();
  const unitPreview2 = useSelector((state: RootState) => state.units.unitPreview2);
  const unitPreview2Status = useSelector((state: RootState) => state.units.status);
  const { academyUpgrades2, blacksmithUpgrades2, unitAttackUpgrades2, unitDefenceUpgrades2 } = useSelector((state: RootState) => state.units);

  useEffect(() => {
    dispatch(recalculateUnitPreview2());
  }, [academyUpgrades2, blacksmithUpgrades2, unitAttackUpgrades2, unitDefenceUpgrades2, dispatch]);

  if (unitPreview2Status === 'loading') {
    return <p>Loading...</p>;
  }

  if (unitPreview2Status === 'failed') {
    return <p>Error loading unit preview</p>;
  }

  return (
    <div>
      {unitPreview2 ? (
        <UnitPreview upgradetUnit={unitPreview2} />
      ) : (
        <p>No unit preview available</p>
      )}
    </div>
  );
};

export default Unit2Preview;
