

import Layout from '../../layout/layout';
import './../../App.css';
import "./units.css";
import Nation1Select from '../../components/units/unitOne/Nation1Select';
import Nation2Select from '../../components/units/unitTwo/Nation2Select';
import Unit1Select from '../../components/units/unitOne/Unit1Select';
import Unit2Select from '../../components/units/unitTwo/Unit2Select';
import Academy1Upgrades from '../../components/units/unitOne/Academy1Upgrades';
import Blacksmith1Upgrades from '../../components/units/unitOne/Blacksmith1Upgrades';
import Unit1Upgrades from '../../components/units/unitOne/Unit1Upgrades';
import Unit2Upgrades from '../../components/units/unitTwo/Unit2Upgrades';
import Academy2Upgrades from '../../components/units/unitTwo/Academy2Upgrades';
import Blacksmith2Upgrades from '../../components/units/unitTwo/Blacksmith2Upgrades';
import store from '../../store';
import { Provider } from 'react-redux';
import Unit2Preview from '../../components/units/unitTwo/Unit2Preview';
import Unit1Preview from '../../components/units/unitOne/Unit1Preview';
import CounterUnits from '../../components/units/CounterUnits';
const Units = () => {

    return (
        <Layout>
            <Provider store={store}>
                <div className='wrapper'>
                    <div className='wrapper-unit'>
                        <div className='wrapper-select'>
                            <Nation1Select />
                            <Unit1Select />
                        </div>
                        <Unit1Upgrades />
                        <Academy1Upgrades name='academy1' />
                        <Blacksmith1Upgrades name='blacksmith1' />
                        <Unit1Preview />
                    </div>
                    <div>
                        <CounterUnits />

                    </div>
                    <div className='wrapper-unit'>
                        <div className='wrapper-select'>
                            <Nation2Select />
                            <Unit2Select />
                        </div>
                        <Unit2Upgrades />
                        <Academy2Upgrades name='academy2' />
                        <Blacksmith2Upgrades name='blacksmith2' />
                        <Unit2Preview />
                    </div>
                </div>
            </Provider>
        </Layout>
    );
}
export default Units;