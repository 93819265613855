import React, { useEffect, useRef, useState } from 'react';
import { Nation, Unit } from '../../context/interfaces';
import { useDispatch } from 'react-redux';
import { resetStates } from '../../store/unitsSlice';
import UnitImage from './UnitImage';
import { ArrowDropDown } from '@mui/icons-material';
import { ArrowDropUp } from '@mui/icons-material';
type Props = {
  name: string;
  selectedNation: Nation | null;
  selectedUnitNation: Unit | null;
  setSelectedUnitNation: (unit: Unit | null) => void;
};
const UnitSelect = ({ name, selectedNation, setSelectedUnitNation, selectedUnitNation }: Props) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedUnit(''); // Reset the selected unit when nation changes
    if (selectedNation) {
      setUnits(selectedNation.units);
    } else {
      setUnits([]);
    }
  }, [selectedNation]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleSelectedUnit = (unitName: string) => {

    if (name === 'unit1') {
      dispatch(resetStates('unit1'));
    } else if (name === 'unit2') {
      dispatch(resetStates('unit2'));
    }
    setSelectedUnit(unitName);
    setDropDownOpen(false);
    if (selectedNation) {
      const selectedUnit = selectedNation.units.find((unit: Unit) => unit.name === unitName) || null;
      setSelectedUnitNation(selectedUnit);
    }
  };

  return (
    <div className="custom-select-unit" ref={dropdownRef}>
      <div className="custom-selected-unit" onClick={() => setDropDownOpen(!dropDownOpen)}>
        {selectedUnitNation !== null && selectedUnit !== '' ?
          <UnitImage id={selectedUnitNation?.id || ''} />
          : "Select Unit"
        }
        {dropDownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        {selectedUnit !== '' && (
          <div className='tooltip-unit'><span>{selectedUnit}</span></div>
        )}
      </div>
      <div className={`custom-dropdown-unit ${!dropDownOpen ? 'dropdown-open' : ''}`}>
        {units.map((unit: Unit) => (
          <div
            key={unit.id}
            className="dropdown-item"
            onClick={() => handleSelectedUnit(unit.name)}
          >
            <div className="unit-image-unit">
              <div className={unit.id + " unit-icon"} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnitSelect;
