import protection_arrow from '../../assets/protection_arrow.png';
import protection_bullet from '../../assets/protection_bullet.png';
import protection_cannister from '../../assets/protection_cannister.png';
import protection_cannonball from '../../assets/protection_cannonball.png';
import protection_pike from '../../assets/protection_pike.png';
import protection_sword from '../../assets/protection_sword.png';
const UnitProtection = ({protection} : any) => {
    return (
        <div className='wrapper wrapper-protection'>
            <span>
                <img src={protection_arrow} alt="protection arrow" />
                <div>{protection?.arrow}</div>
            </span>
            <span>
                <img src={protection_bullet} alt="protection bullet" />
                <div>{protection?.bullet}</div>
            </span>
            <span>
                <img src={protection_cannister} alt="protection cannister" />
                <div>{protection?.cannister}</div>
            </span>
            <span>
                <img src={protection_cannonball} alt="protection cannonball" />
                <div>{protection?.cannonball} </div>
            </span>
            <span>
                <img src={protection_pike} alt="protection pike" />
                <div>{protection?.pike}</div>
            </span>
            <span>
                <img src={protection_sword} alt="protection sword" />
                <div>{protection?.sword} </div>
            </span>
        </div>
    )
};
export default UnitProtection;