import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BlacksmithUpgradeItem from '../BlacksmithUpgradeItem';
import { BlacksmithUpgrade } from '../../../context/interfaces';
import { RootState } from '../../../store'; // Adjust the path as necessary

type Props = {
  name: string;
};

const Blacksmith1Upgrades = ({ name }: Props) => {
  // Use useSelector to access Redux state
  const selectedNation1 = useSelector((state: RootState) => state.units.selectedNation1);
  const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);
  const [blacksmithUpgrades, setBlacksmithUpgrades] = useState<BlacksmithUpgrade[]>([]);

  useEffect(() => {
    if (selectedUnitNation1 && selectedNation1) {
      const upgrades = selectedNation1.blacksmithUpgrades.filter((upgrade) =>
        upgrade.affectedUnits?.includes(selectedUnitNation1.id)
      );
      setBlacksmithUpgrades(upgrades || []);
    } else {
      setBlacksmithUpgrades([]);
    }
  }, [selectedUnitNation1, selectedNation1]);

  return (
    <div className='blacksmith-wrapper'>
      {blacksmithUpgrades.length > 0 ? (
        blacksmithUpgrades.map((upgrade: BlacksmithUpgrade) => (
          <div key={upgrade.id}>
            <BlacksmithUpgradeItem
              index={upgrade.id}
              upgrade={upgrade}
              name={name}
              selectedUnitNation={selectedUnitNation1}
            />
          </div>
        ))
      ) : (
        <p>No upgrades available</p>
      )}
    </div>
  );
};

export default Blacksmith1Upgrades;
