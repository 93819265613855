import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AcademyUpgradeItem from '../AcademyUpgradeItem';
import { AcademyUpgrade } from '../../../context/interfaces';
import { RootState } from '../../../store'; // Adjust the path as necessary

type Props = {
  name: string;
};

const Academy2Upgrades = ({ name }: Props) => {
  // Use useSelector to access Redux state
  const selectedNation2 = useSelector((state: RootState) => state.units.selectedNation2);
  const selectedUnitNation2 = useSelector((state: RootState) => state.units.selectedUnitNation2);
  const [academyUpgrades, setAcademyUpgrades] = useState<AcademyUpgrade[]>([]);
  useEffect(() => {
    if (selectedUnitNation2 && selectedNation2) {
      const upgrades = selectedNation2.academyUpgrades.filter((upgrade) => 
        upgrade.affectedUnits?.includes(selectedUnitNation2.id)
      );
      setAcademyUpgrades(upgrades || []);
    } else {
      setAcademyUpgrades([]);
    }
  }, [selectedUnitNation2, selectedNation2]);

  return (
    <div className='academy-wrapper'>
      {academyUpgrades.length > 0 ? (
        academyUpgrades.map((upgrade) => (
          <div key={upgrade.id}>
            <AcademyUpgradeItem name={name} index={upgrade.id} upgrade={upgrade} selectedUnitNation={selectedUnitNation2} />
          </div>
        ))
      ) : (
        <p>No upgrades available</p>
      )}
    </div>
  );
};

export default Academy2Upgrades;
