// components/Unit1Upgrades.tsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Upgrades } from '../../../context/interfaces';
import Upgrade1Attack from './Upgrade1Attack';
import Upgrade1Defence from './Upgrade1Defence';

const Unit1Upgrades = () => {
  const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);
  const [upgrades, setUpgrades] = useState<Upgrades | undefined>();

  useEffect(() => {
    setUpgrades({
      attack: selectedUnitNation1?.upgrades?.attack,
      defence: selectedUnitNation1?.upgrades?.defence
    });
  }, [selectedUnitNation1]);

  return (
    upgrades ? (
      <div className='unit-upgrade-wrap'>
        <Upgrade1Attack options={upgrades.attack} />
        <Upgrade1Defence options={upgrades.defence} />
      </div>
    ) : null
  );
};

export default Unit1Upgrades;
