import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AcademyUpgrade, BlacksmithUpgrade, Nation, Unit, Upgrade } from '../context/interfaces';
import { RootState } from '.';

interface UnitsState {
  selectedNation1: Nation | null;
  selectedNation2: Nation | null;
  selectedUnitNation1: Unit | null;
  selectedUnitNation2: Unit | null;
  unitPreview1: Unit | null;
  unitPreview2: Unit | null;
  academyUpgrades1: AcademyUpgrade[];
  academyUpgrades2: AcademyUpgrade[];
  blacksmithUpgrades1: BlacksmithUpgrade[];
  blacksmithUpgrades2: BlacksmithUpgrade[];
  unitAttackUpgrades1: Upgrade[];
  unitAttackUpgrades2: Upgrade[];
  unitDefenceUpgrades1: Upgrade[];
  unitDefenceUpgrades2: Upgrade[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: UnitsState = {
  selectedNation1: null,
  selectedNation2: null,
  selectedUnitNation1: null,
  selectedUnitNation2: null,
  unitPreview1: null,
  unitPreview2: null,
  academyUpgrades1: [],
  academyUpgrades2: [],
  blacksmithUpgrades1: [],
  blacksmithUpgrades2: [],
  unitAttackUpgrades1: [],
  unitAttackUpgrades2: [],
  unitDefenceUpgrades1: [],
  unitDefenceUpgrades2: [],
  status: 'idle'
};

// Utility function to apply upgrades
const applyUpgrades = (
  unit: Unit | null,
  attackUpgrades: Upgrade[],
  defenceUpgrades: Upgrade[],
  academyUpgrades: AcademyUpgrade[],
  blacksmithUpgrades: BlacksmithUpgrade[]
): Unit | null => {
  if (!unit) return null;
  let newUnit = JSON.parse(JSON.stringify(unit));

  // Apply attack upgrades
  attackUpgrades.forEach(upgrade => {
    if (newUnit.weapons) {
      if (upgrade.sword !== undefined && newUnit.weapons.sword) {
        newUnit.weapons.sword = { ...newUnit.weapons.sword, damage: (newUnit.weapons.sword.damage || 0) + upgrade.sword };
      }
      if (upgrade.pike !== undefined && newUnit.weapons.pike) {
        newUnit.weapons.pike = { ...newUnit.weapons.pike, damage: (newUnit.weapons.pike.damage || 0) + upgrade.pike };
      }
      if (upgrade.bullet !== undefined) {
        if (newUnit.weapons.bullet.damage !== undefined) {
          newUnit.weapons.bullet = { ...newUnit.weapons.bullet, damage: (newUnit.weapons.bullet.damage || 0) + upgrade.bullet };
        }
      }
      if (upgrade.mortarball !== undefined && newUnit.weapons.mortarball) {
        newUnit.weapons.mortarball = { ...newUnit.weapons.mortarball, damage: (newUnit.weapons.mortarball.damage || 0) + upgrade.mortarball };
      }
    }
  });
  // Apply defence upgrades
  defenceUpgrades.forEach(upgrade => {
    if (newUnit.protection) {
      if (upgrade.sword !== undefined) {
        newUnit.protection.sword = (newUnit.protection.sword || 0) + upgrade.sword;
      }
      if (upgrade.pike !== undefined) {
        newUnit.protection.pike = (newUnit.protection.pike || 0) + upgrade.pike;
      }
      if (upgrade.bullet !== undefined) {
        newUnit.protection.bullet = (newUnit.protection.bullet || 0) + upgrade.bullet;
      }
      if (upgrade.arrow !== undefined) {
        newUnit.protection.arrow = (newUnit.protection.arrow || 0) + upgrade.arrow;
      }
      if (upgrade.cannister !== undefined) {
        newUnit.protection.cannister = (newUnit.protection.cannister || 0) + upgrade.cannister;
      }
      if (upgrade.cannonball !== undefined) {
        newUnit.protection.cannonball = (newUnit.protection.cannonball || 0) + upgrade.cannonball;
      }
    }
  });
  blacksmithUpgrades.forEach(upgrade => {
    if (newUnit.weapons) {
      if (upgrade.type === 'buildtime' && upgrade.value !== undefined && newUnit.buildtime !== undefined) {
        const percentageReduction = Math.abs(upgrade.value);
        newUnit.buildtime = Math.floor(newUnit.buildtime * (1 - percentageReduction / 100))
      }
      if (upgrade.type === 'damage' && upgrade.value !== undefined) {
        if (newUnit.weapons.pike && newUnit.weapons.pike.damage !== undefined && upgrade.pike !== undefined) {
          newUnit.weapons.pike = { ...newUnit.weapons.pike, damage: newUnit.weapons.pike.damage + upgrade.value };
        }
        if (newUnit.weapons.sword && newUnit.weapons.sword.damage !== undefined && upgrade.sword !== undefined) {
          newUnit.weapons.sword = { ...newUnit.weapons.sword, damage: newUnit.weapons.sword.damage + upgrade.value };
        }
      }
      if (upgrade.type === 'shield' && upgrade.value !== undefined && newUnit.shield !== undefined) {
        newUnit.shield += upgrade.value;
      }
    }
  });
  const baseDamage = newUnit.weapons?.bullet?.damage || 0;
  // Apply academy upgrades
  academyUpgrades.forEach(upgrade => {
    if (newUnit.weapons) {
      if (upgrade.type === 'damage' && upgrade.value !== undefined && newUnit.weapons) {
        if (newUnit.weapons.pike && newUnit.weapons.pike.damage !== undefined && upgrade.pike !== undefined) {
          newUnit.weapons.pike = { ...newUnit.weapons.pike, damage: newUnit.weapons.pike.damage + upgrade.value };
        }
        if (newUnit.weapons.sword && newUnit.weapons.sword.damage !== undefined && upgrade.sword !== undefined) {
          newUnit.weapons.sword = { ...newUnit.weapons.sword, damage: newUnit.weapons.sword.damage + upgrade.value };
        }
      }
      if (upgrade.type === 'damagepercent' && upgrade.value !== undefined) {
        if (newUnit.weapons.bullet && newUnit.weapons.bullet.damage !== undefined && upgrade.bullet !== undefined) {
          newUnit.weapons.bullet = { ...newUnit.weapons.bullet, damage: newUnit.weapons.bullet.damage + baseDamage * (upgrade.value / 100) };
        }
        if (newUnit.weapons.pike && newUnit.weapons.pike.damage !== undefined && upgrade.pike !== undefined) {
          newUnit.weapons.pike = { ...newUnit.weapons.pike, damage: newUnit.weapons.pike.damage + newUnit.weapons.pike.damage * (upgrade.value / 100) };
        }
      }
      if (upgrade.type === 'attackpause' && upgrade.value !== undefined && newUnit.weapons.bullet && newUnit.weapons.bullet.pause !== undefined) {

        const percentageReduction = Math.abs(upgrade.value);

        newUnit.weapons.bullet = {
          ...newUnit.weapons.bullet,
          pause: Math.floor(newUnit.weapons.bullet.pause * (1 - percentageReduction / 100))
        };
      }
      if (upgrade.type === 'shield' && upgrade.value !== undefined && newUnit.shield !== undefined) {
        newUnit.shield += upgrade.value;
      }
      if (upgrade.type === 'pricepercentage' && upgrade.value !== undefined && newUnit.cost) {
        newUnit.cost = {
          ...newUnit.cost,
          coal: Math.floor(newUnit.cost.coal * (1 - 50 / 100)),
          iron: Math.floor(newUnit.cost.iron * (1 - 50 / 100)),
          wood: Math.floor(newUnit.cost.wood * (1 - 50 / 100)),
          gold: Math.floor(newUnit.cost.gold * (1 - 50 / 100)),
          stone: Math.floor(newUnit.cost.stone * (1 - 50 / 100))
        };
      }

    }
  });
  if (newUnit.weapons.bullet) {
    newUnit.weapons.bullet = { ...newUnit.weapons.bullet, damage: Math.floor(newUnit.weapons.bullet.damage) };
  }
  if (newUnit.weapons.pike) {
    newUnit.weapons.pike = { ...newUnit.weapons.pike, damage: Math.floor(newUnit.weapons.pike.damage) };
  }
  if (newUnit.weapons.sword) {
    newUnit.weapons.sword = { ...newUnit.weapons.sword, damage: Math.floor(newUnit.weapons.sword.damage) };
  }

  return newUnit;
};

export const recalculateUnitPreview1 = createAsyncThunk(
  'units/recalculateUnitPreview1',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const sortedAcademyUpgrades = [...state.units.academyUpgrades1].sort((a, b) => (b.value ?? 0) - (a.value ?? 0));
    return applyUpgrades(
      state.units.selectedUnitNation1,
      state.units.unitAttackUpgrades1,
      state.units.unitDefenceUpgrades1,
      sortedAcademyUpgrades,
      state.units.blacksmithUpgrades1
    );
  }
);

export const recalculateUnitPreview2 = createAsyncThunk(
  'units/recalculateUnitPreview2',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const sortedAcademyUpgrades = [...state.units.academyUpgrades2].sort((a, b) => (b.value ?? 0) - (a.value ?? 0));
    return applyUpgrades(
      state.units.selectedUnitNation2,
      state.units.unitAttackUpgrades2,
      state.units.unitDefenceUpgrades2,
      sortedAcademyUpgrades,
      state.units.blacksmithUpgrades2
    );
  }
);

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setSelectedNation1(state, action: PayloadAction<Nation | null>) {
      state.selectedNation1 = action.payload;
    },
    setSelectedNation2(state, action: PayloadAction<Nation | null>) {
      state.selectedNation2 = action.payload;
    },
    setSelectedUnitNation1(state, action: PayloadAction<Unit | null>) {
      state.selectedUnitNation1 = action.payload;
      state.unitPreview1 = action.payload;
    },
    setSelectedUnitNation2(state, action: PayloadAction<Unit | null>) {
      state.selectedUnitNation2 = action.payload;
      state.unitPreview2 = action.payload;
    },
    setUnitPreview1(state, action: PayloadAction<Unit | null>) {
      state.unitPreview1 = action.payload;
    },
    setUnitPreview2(state, action: PayloadAction<Unit | null>) {
      state.unitPreview2 = action.payload;
    },
    addAcademyUpgrade1(state, action: PayloadAction<AcademyUpgrade>) {
      state.academyUpgrades1.push(action.payload);
    },
    addAcademyUpgrade2(state, action: PayloadAction<AcademyUpgrade>) {
      state.academyUpgrades2.push(action.payload);
    },
    addBlacksmithUpgrade1(state, action: PayloadAction<BlacksmithUpgrade>) {
      state.blacksmithUpgrades1.push(action.payload);
    },
    addBlacksmithUpgrade2(state, action: PayloadAction<BlacksmithUpgrade>) {
      state.blacksmithUpgrades2.push(action.payload);
    },
    addUnitAttackUpgrade1(state, action: PayloadAction<Upgrade>) {
      state.unitAttackUpgrades1.push(action.payload);
    },
    addUnitAttackUpgrade2(state, action: PayloadAction<Upgrade>) {
      state.unitAttackUpgrades2.push(action.payload);
    },
    addUnitDefenceUpgrade1(state, action: PayloadAction<Upgrade>) {
      state.unitDefenceUpgrades1.push(action.payload);
    },
    addUnitDefenceUpgrade2(state, action: PayloadAction<Upgrade>) {
      state.unitDefenceUpgrades2.push(action.payload);
    },
    resetStates(state, action: PayloadAction<'unit1' | 'unit2'>) {
      if (action.payload === 'unit1') {
        state.academyUpgrades1 = [];
        state.blacksmithUpgrades1 = [];
        state.unitAttackUpgrades1 = [];
        state.unitDefenceUpgrades1 = [];
        state.selectedUnitNation1 = null;
        state.unitPreview1 = null;
        state.status = 'idle';
      } else {
        state.academyUpgrades2 = [];
        state.blacksmithUpgrades2 = [];
        state.unitAttackUpgrades2 = [];
        state.unitDefenceUpgrades2 = [];
        state.selectedUnitNation2 = null;
        state.unitPreview2 = null;
        state.status = 'idle';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recalculateUnitPreview1.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(recalculateUnitPreview1.fulfilled, (state, action) => {
        state.unitPreview1 = action.payload;
        state.status = 'succeeded';
      })
      .addCase(recalculateUnitPreview1.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(recalculateUnitPreview2.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(recalculateUnitPreview2.fulfilled, (state, action) => {
        state.unitPreview2 = action.payload;
        state.status = 'succeeded';
      })
      .addCase(recalculateUnitPreview2.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export const {
  setSelectedNation1,
  setSelectedNation2,
  setSelectedUnitNation1,
  setSelectedUnitNation2,
  setUnitPreview1,
  setUnitPreview2,
  addAcademyUpgrade1,
  addAcademyUpgrade2,
  addBlacksmithUpgrade1,
  addBlacksmithUpgrade2,
  addUnitAttackUpgrade1,
  addUnitAttackUpgrade2,
  addUnitDefenceUpgrade1,
  addUnitDefenceUpgrade2,
  resetStates,
} = unitsSlice.actions;

export default unitsSlice.reducer;
